import { Sheet, Text } from '@/components'
import ChevronRight from '@/app/img/chevron_right.svg'
import { Link } from 'app-components'
import React from 'react'

type InfoCardProps = {
  title: string
  subtitle?: string
  subtitleLink?: string
  onSubtitleLink?: () => void
  image?: React.ReactNode
  altText?: string
  onClick?: () => void
  disabled?: boolean
  sheetColor?: string
  sheetText?: string
  variant: 'small' | 'large'
}

export default function InfoCard(props: InfoCardProps) {
  const onClick = () => {
    if (props.disabled || !props.onClick) {
      return
    }

    props.onClick()
  }

  return (
    <div
      className={`flex justify-between items-center cursor-pointer group`}
      onClick={onClick}
    >
      <div className="flex items-center">
        {props.image && (
          <div
            className={`${
              props.disabled ? 'text-optiwatt-grey-400' : 'text-themed-primary'
            }`}
          >
            {props.image}
          </div>
        )}
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            <Text
              variant={props.variant === 'small' ? 'body1' : 'subheader'}
              className={
                !props.disabled
                  ? 'group-hover:text-themed-primary transition'
                  : 'text-themed-grey-400'
              }
            >
              {props.title}
            </Text>
            {props.sheetText && (
              <Sheet
                rounded
                size="xs"
                className="text-white"
                color={props.sheetColor}
              >
                {props.sheetText}
              </Sheet>
            )}
            {!props.sheetText && props.disabled && (
              <Sheet rounded size="xs" className="text-white" color="grey-400">
                Not Available
              </Sheet>
            )}
          </div>
          {props.subtitle && (
            <Text
              variant="body4"
              className={
                !props.disabled
                  ? 'group-hover:text-themed-grey-500 transition"'
                  : 'text-themed-grey-400'
              }
            >
              {props.subtitle}{' '}
              {props.subtitleLink && (
                <Link onClick={props.onSubtitleLink}>{props.subtitleLink}</Link>
              )}
            </Text>
          )}
        </div>
      </div>
      {!props.disabled && props.onClick && (
        <img
          src={ChevronRight}
          alt="Right arrow"
          className="w-[24px] group-hover:brightness-0 transition"
        />
      )}
    </div>
  )
}
